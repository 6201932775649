import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from '../../css/pages/Blog.module.scss';

import SEO from '../../components/layout/SEO';
import Layout from '../../components/layout/Layout';

import { formatDate } from '../../util';

const BlogTemplate = () => {
    const data = useStaticQuery(graphql`
        {
            allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/(blog)/" } }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                edges {
                    node {
                        frontmatter {
                            publish
                            category
                            title
                            shortTitle
                            path
                            date
                            featuredimage {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: FULL_WIDTH
                                        placeholder: BLURRED
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const blogData = data.allMarkdownRemark.edges;

    return (
        <>
            <SEO
                title="Blog"
                description="We share updates and stories from all areas of Ripples, covering internal affairs, tech and community success stories"
                path="/blog/"
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                schemaData={[schemaData]}
            />
            <Layout mainClass={styles.Blog}>
                <h1>Keep updated with all things Ripples</h1>
                <hr />
                <div className={styles.blogContainer}>
                    {blogData.map((blog, i) => {
                        return blog.node.frontmatter.publish ? (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`blog-${i}`} className={styles.blogStory}>
                                <Link to={blog.node.frontmatter.path}>
                                    <h2>
                                        {blog.node.frontmatter.shortTitle ||
                                            blog.node.frontmatter.title}
                                    </h2>
                                    <GatsbyImage
                                        image={
                                            blog.node.frontmatter.featuredimage
                                                .childImageSharp.gatsbyImageData
                                        }
                                        alt={`${blog.node.frontmatter.title}-featured-image`}
                                        className={styles.featuredImage}
                                    />
                                </Link>
                                <div className={styles.meta}>
                                    <Link
                                        to={`/blog/${blog.node.frontmatter.category
                                            .replace(/\W+/g, '-')
                                            .toLowerCase()}/`}>
                                        <h3>
                                            {blog.node.frontmatter.category}
                                        </h3>
                                    </Link>
                                    <p>
                                        {formatDate(
                                            blog.node.frontmatter.date,
                                            'short',
                                            'short',
                                        )}
                                    </p>
                                </div>
                            </div>
                        ) : null;
                    })}
                </div>
            </Layout>
        </>
    );
};

const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'Organization',
            '@id': 'https://www.joinripples.org/#organization',
            name: 'Ripples',
            url: 'https://www.joinripples.org/',
            sameAs: [],
            logo: {
                '@type': 'ImageObject',
                '@id': 'https://www.joinripples.org/#logo',
                url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                width: 500,
                height: 500,
                caption: 'Ripples',
            },
            image: {
                '@id': 'https://www.joinripples.org/#logo',
            },
        },
        {
            '@type': 'WebSite',
            '@id': 'https://www.joinripples.org/#website',
            url: 'https://www.joinripples.org/',
            name: 'Ripples',
            description: 'Donate pennies as you spend',
            publisher: {
                '@id': 'https://www.joinripples.org/#organization',
            },
        },
        {
            '@type': 'CollectionPage',
            '@id': 'https://www.joinripples.org/blog/#webpage',
            url: 'https://www.joinripples.org/blog/',
            inLanguage: 'en-GB',
            name: 'Blog | Ripples',
            isPartOf: {
                '@id': 'https://www.joinripples.org/#website',
            },
        },
    ],
};

export default BlogTemplate;
